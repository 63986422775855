import React, { ReactElement } from 'react'
import './OurPropertiesListView.scss'
import loadable from '@loadable/component'
import { PropertyCardTypes } from '../../../components/PropertyCard/PropertyCard.types'

const NoResult = loadable(() => import('../../../components/NoResult/NoResult'))
const PropertyCard = loadable(
  () => import('../../../components/PropertyCard/PropertyCard'),
)
const Pagination = loadable(
  () => import('../../../components/Pagination/Pagination'),
)

type OurPropertiesListViewPropTypes = {
  searchResults: PropertyCardTypes[]
  pages: number
  currentPage: number
  onPageClick(page: number): void
  searchTerm?: string
  currentSets: number
  handleAddSets(): void
}

const OurPropertiesListView = ({
  searchResults,
  pages,
  currentPage,
  onPageClick,
  searchTerm,
  currentSets,
  handleAddSets,
}: OurPropertiesListViewPropTypes): ReactElement => {
  return (
    <>
      <div className="our-properties-list-view">
        {searchResults.length ? (
          searchResults.map((property, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <PropertyCard key={property.slug + index} data={property} />
          ))
        ) : (
          <NoResult searchTerm={searchTerm} />
        )}
      </div>
      <div className="our-properties-list-view-pagination">
        <Pagination
          pages={pages}
          currentPage={currentPage}
          onPageClick={onPageClick}
        />
      </div>
      {currentSets < pages && (
        <div className="our-properties-list-view-load-more">
          <button
            type="button"
            className="our-properties-list-view-load-more-button"
            onClick={handleAddSets}
          >
            Load More
          </button>
        </div>
      )}
    </>
  )
}

export default OurPropertiesListView
