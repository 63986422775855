import React, { ReactElement, memo, useState, useEffect } from 'react'
import './OurPropertiesMapView.scss'
import loadable from '@loadable/component'
import { PropertyCardTypes } from '../../../components/PropertyCard/PropertyCard.types'
import getDistance from '../../../services/getDistance'
import { OurPropertiesPropertyTypes } from '../OurProperties.types'

const PropertyCard = loadable(
  () => import('../../../components/PropertyCard/PropertyCard'),
)

const OutdoorMap = loadable(
  () => import('../../../components/OutdoorMap/OutdoorMap'),
)

const DISTANCE_RADIUS = 5000

export type DeviceLocationTypes = {
  lat: number
  lng: number
}

type PropertiesWithGeoLocationTypes = OurPropertiesPropertyTypes & {
  position: DeviceLocationTypes
}

type OurPropertiesMapViewPropTypes = {
  searchResults: OurPropertiesPropertyTypes[]
  pagePropertyList: OurPropertiesPropertyTypes[]
  deviceLocation?: DeviceLocationTypes
  pages: number
  currentSets: number
  handleAddSets(): void
}

const OurPropertiesMapView = ({
  searchResults,
  deviceLocation,
  pagePropertyList,
  pages,
  currentSets,
  handleAddSets,
}: OurPropertiesMapViewPropTypes): ReactElement => {
  const [propertiesWithGeoLocation, setPropertiesWithGeoLocation] = useState<
    PropertiesWithGeoLocationTypes[]
  >(
    searchResults.filter(
      (result) => result.position,
    ) as PropertiesWithGeoLocationTypes[],
  )
  const [propertiesWithinRadius, setPropertiesWithinRadius] = useState<
    PropertiesWithGeoLocationTypes[]
  >([])
  const [mapOffset, setMapOffset] = useState<number | null>(null)
  const [propertyPinClicked, setPropertyPinClicked] =
    useState<PropertyCardTypes | null>(null)
  const [viewPinClicked, setViewPinCLicked] = useState<JSX.Element | null>(null)

  const propertiesFeaturedList = searchResults.map((searchResult, index) => (
    <PropertyCard
      // eslint-disable-next-line react/no-array-index-key
      key={searchResult.slug + index}
      data={searchResult}
      className="item"
    />
  ))

  const featuredList = viewPinClicked || propertiesFeaturedList

  useEffect(() => {
    setMapOffset(window.innerWidth > 600 ? 200 : 0)
  }, [])

  useEffect(() => {
    setPropertiesWithGeoLocation(
      searchResults.filter(
        (result) => result.position,
      ) as PropertiesWithGeoLocationTypes[],
    )
  }, [searchResults])

  useEffect(() => {
    if (deviceLocation) {
      setPropertiesWithinRadius(
        propertiesWithGeoLocation.filter((property) => {
          const distance = getDistance(deviceLocation, property.position)
          return distance <= DISTANCE_RADIUS
        }),
      )
    }
  }, [deviceLocation, propertiesWithGeoLocation])

  useEffect(() => {
    if (propertyPinClicked) {
      const propertyCard = (
        <PropertyCard data={propertyPinClicked} className="item" />
      )
      setViewPinCLicked(propertyCard)
    }
  }, [propertyPinClicked])

  const handlePinClick = (data: PropertyCardTypes): void => {
    setPropertyPinClicked(data)
  }

  return (
    <div className="our-properties">
      <div className="our-properties-map-view">
        {mapOffset !== null && deviceLocation && (
          <OutdoorMap
            id="map"
            center={deviceLocation}
            radius={DISTANCE_RADIUS}
            offset={mapOffset}
            zoom={12}
            markerLocations={propertiesWithinRadius.map(
              (propertyWithinRadius) => ({
                position: propertyWithinRadius.position,
                data: propertyWithinRadius,
                handlePinClick,
              }),
            )}
          />
        )}
        <div className="our-properties-featured-list">{featuredList}</div>
      </div>
      <div className="our-properties-featured-list-hide">
        {pagePropertyList.map((searchResult) => (
          <PropertyCard
            key={searchResult.slug}
            data={searchResult}
            className="item"
          />
        ))}
      </div>
      {currentSets < pages && (
        <div className="our-properties-featured-list-load-more">
          <button
            type="button"
            className="our-properties-featured-list-load-more-button"
            onClick={handleAddSets}
          >
            Load More
          </button>
        </div>
      )}
    </div>
  )
}

export default memo(OurPropertiesMapView)
