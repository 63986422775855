export const PROPERTIES_PER_PAGE = 9

export const parseValue = (price: string): number => {
  if (price === 'ABOVE') {
    return 1e20
  }

  return parseFloat(price.replace('M', '')) * 1e6
}

export const parseRange = (range: string): number[] | null => {
  const [lowestValue, highestValue] = range.split(' - ')

  if (!highestValue) {
    return null
  }

  return [parseValue(lowestValue), parseValue(highestValue)]
}

export const PAGE_SEO_DEFAULT_VALUE = {
  pageTitle: 'Properties in the Philippines | Real Estate | Avida Land',
  pageHeader: 'Our Properties',
  subHeader:
    'Discover a wide range of real estate options - condos, house and lot properties, and more. Find your ideal home today.',
  description:
    'Discover a wide range of real estate options, including condos, house and lot properties across the Philippines.',
}
