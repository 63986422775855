const getDeviceLocation = (): Promise<{
  coords: { latitude: number; longitude: number } | {}
}> =>
  new Promise((resolve, reject) => {
    if (typeof window !== 'undefined' && 'navigator' in window) {
      window.navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position)
        },
        (error) => {
          reject(error)
        },
      )
      return
    }
    resolve({ coords: {} })
  })

export default getDeviceLocation
