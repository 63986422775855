import React, { ReactElement, useState, useCallback, useEffect } from 'react'
import { graphql } from 'gatsby'
import Switch from 'react-switch'
import Layout from '../../layouts/Layout/Layout'
import './OurProperties.scss'
import plusIcon from '../../assets/svg/plus-search.svg'
import minusIcon from '../../assets/svg/minus-search.svg'
import OurPropertiesListView from './OurPropertiesListView/OurPropertiesListView'
import OurPropertiesMapView from './OurPropertiesMapView/OurPropertiesMapView'
import getDeviceLocation from '../../services/getDeviceLocation'
import searchOptions from './OurProperties.json'
import Seo from '../../components/Seo/Seo'
import PropertySearchInput from '../../components/PropertySearchInput/PropertySearchInput'
import PropertySearchSelect from '../../components/PropertySearchSelect/PropertySearchSelect'
import PropertySearch from '../../components/PropertySearch/PropertySearch'
import { sortByDistance } from '../../services/getDistance'
import {
  OurPropertiesPropTypes,
  OurPropertiesPropertyTypes,
  NullableOptionTypes,
  SearchTypes,
} from './OurProperties.types'
import {
  PROPERTIES_PER_PAGE,
  parseRange,
  PAGE_SEO_DEFAULT_VALUE,
} from './OurProperties.utils'

const OurProperties = ({
  data: {
    allProperty: { nodes: allProperty },
    homePageSeo: { seoPropertiesTitle, seoPropertiesDescription },
  },
  location: { state: locationState },
  pageContext,
}: OurPropertiesPropTypes): ReactElement => {
  const [isPageSEO, setIsPageTitle] = useState(PAGE_SEO_DEFAULT_VALUE)
  const [filterType, setFilterType] = useState('')

  const {
    searchTerm: defaultSearchTerm = '',
    searchType: defaultSearchType,
    searchPrice: defaultSearchPrice,
    searchLocation: defaultSearchLocation,
    searchBedroom: defaultSearchBedroom,
    searchUnitType: defaultSearchUnitType,
  } = locationState || {}

  const [propertyList] = useState<OurPropertiesPropertyTypes[]>(allProperty)
  const [searchedPropertyList, setSearchedPropertyList] =
    useState<OurPropertiesPropertyTypes[]>(propertyList)

  const [pagePropertyList, setPagePropertyList] = useState<
    OurPropertiesPropertyTypes[]
  >(searchedPropertyList.slice(0, PROPERTIES_PER_PAGE))

  const [searchTerm, setSearchTerm] = useState<string>(defaultSearchTerm)
  const [searchType, setSearchType] = useState<NullableOptionTypes>(
    defaultSearchType || null,
  )
  const [locationStateSearch, setLocationStateSearch] = useState<any>(
    locationState?.searchType || '',
  )

  const [searchPriceRange, setSearchPriceRange] = useState<NullableOptionTypes>(
    defaultSearchPrice || null,
  )
  const [searchLocation, setSearchLocation] = useState<NullableOptionTypes>(
    defaultSearchLocation || null,
  )
  const [searchBedroom, setSearchBedroom] = useState<NullableOptionTypes>(
    defaultSearchBedroom || null,
  )
  const [searchUnitType, setSearchUnitType] = useState<NullableOptionTypes>(
    defaultSearchUnitType || null,
  )
  const [show, setShow] = useState(false)
  const [checked, setChecked] = useState(false)

  const [pages, setPages] = useState(
    Math.ceil(searchedPropertyList.length / PROPERTIES_PER_PAGE),
  )
  const [currentPage, setCurrentPage] = useState(1)
  const [currentSets, setCurrentSets] = useState(1)
  const [showSearch, setSearch] = useState(false)
  const [isStick, setStick] = useState<boolean>(false)

  const [deviceLocation, setDeviceLocation] = useState<
    | {
        lat: number
        lng: number
      }
    | undefined
  >(undefined)

  const handleAddSets = useCallback((): void => {
    const newSetCount = currentSets + 1
    setPagePropertyList(
      propertyList.slice(0, newSetCount * PROPERTIES_PER_PAGE),
    )
    setCurrentSets(newSetCount)
  }, [currentSets, propertyList])

  useEffect(() => {
    setPages(Math.ceil(searchedPropertyList.length / PROPERTIES_PER_PAGE))
  }, [searchedPropertyList])

  useEffect(() => {
    setPagePropertyList(
      searchedPropertyList.slice(
        (currentPage - 1) * PROPERTIES_PER_PAGE,
        currentPage * PROPERTIES_PER_PAGE,
      ),
    )
  }, [currentPage, searchedPropertyList])

  const handleSearch = useCallback((values: SearchTypes) => {
    setSearchTerm(values.searchTerm || '')
    setSearchType(values.searchType || null)
    setSearchPriceRange(values.searchPrice || null)
    setSearchLocation(values.searchLocation || null)
    setSearchBedroom(values.searchBedroom || null)
    setSearchUnitType(values.searchUnitType || null)
  }, [])

  const handleSearchPriceRangeSelect = useCallback((selected) => {
    setSearchPriceRange(selected)
  }, [])

  const handleSearchLocationSelect = useCallback((selected) => {
    setSearchLocation(selected)
  }, [])

  const handleSearchBedroom = useCallback((selected) => {
    setSearchBedroom(selected)
  }, [])

  const handleSearchUnitType = useCallback((selected) => {
    setSearchUnitType(selected)
  }, [])

  const handlePageClick = useCallback((page: number): void => {
    window.scrollTo({
      top: 300,
    })
    setCurrentPage(page)
  }, [])

  const handleSearchTypeSelect = useCallback((selected) => {
    setSearchType(selected)

    setSearchBedroom(null)
    setSearchUnitType(null)
  }, [])

  useEffect(() => {
    setCurrentPage(1)
    setCurrentSets(1)
    if (locationState?.searchType) {
      setLocationStateSearch(locationState?.searchType)
    }

    setSearchedPropertyList(
      propertyList
        .filter((property: OurPropertiesPropertyTypes) => {
          let filteredSearchType: any =
            searchType?.value?.toLowerCase().trim() || ''
          const filteredSearchTerm = searchTerm?.toLowerCase().trim() || ''

          const filteredSearchLocation =
            searchLocation?.value.toLowerCase().trim() || ''
          const filteredPriceRange = parseRange(searchPriceRange?.value || '')
          const filteredBedroom = parseInt(searchBedroom?.value || '0', 10)
          const filteredUnitType =
            searchUnitType?.value.toLowerCase().trim() || ''

          if (
            locationState?.searchType?.value?.toLowerCase().trim() !==
            locationStateSearch?.value?.toLowerCase().trim()
          ) {
            filteredSearchType = locationState?.searchType?.value
              .toLocaleLowerCase()
              .trim()
          }

          if (
            filteredSearchTerm !== '' &&
            !property.propertyName
              ?.toLowerCase()
              .includes(filteredSearchTerm) &&
            !property.propertyType
              ?.toLowerCase()
              .includes(filteredSearchTerm) &&
            !property.floorAreaSize
              ?.toLowerCase()
              .includes(filteredSearchTerm) &&
            !property.propertyLocation
              ?.toLowerCase()
              .includes(filteredSearchTerm) &&
            !property.propertyLocationSpecific
              ?.toLowerCase()
              .includes(filteredSearchTerm)
          ) {
            return false
          }

          if (
            filteredSearchType !== '' &&
            !property.propertyType?.toLowerCase().includes(filteredSearchType)
          ) {
            return false
          }

          if (
            filteredSearchLocation !== '' &&
            !property.propertyLocation
              ?.toLowerCase()
              .includes(filteredSearchLocation)
          ) {
            return false
          }

          if (filteredPriceRange) {
            const { priceLowest, priceHighest } = property

            const [filterLowestPrice, filterHighestPrice] = filteredPriceRange

            let shouldIncludeData = false

            if (
              (filterLowestPrice >= priceLowest &&
                filterLowestPrice <= priceHighest) ||
              (filterHighestPrice >= priceLowest &&
                filterHighestPrice <= priceHighest)
            ) {
              shouldIncludeData = true
            }

            return shouldIncludeData
          }

          if (filteredBedroom && !property.bedroom.includes(filteredBedroom)) {
            return false
          }

          if (
            filteredUnitType &&
            !property.propertySubTypes
              .map((subType) => subType.toLowerCase().trim())
              .includes(filteredUnitType)
          ) {
            return false
          }

          return true
        })
        .sort((a, b) =>
          sortByDistance(
            a.position,
            b.position,
            deviceLocation || {
              lat: 0,
              lng: 0,
            },
          ),
        ),
    )
  }, [
    locationState,
    propertyList,
    searchTerm,
    searchType,
    searchLocation,
    searchPriceRange,
    searchBedroom,
    searchUnitType,
    deviceLocation,
  ])

  useEffect(() => {
    if (!deviceLocation) {
      getDeviceLocation().then((geoLocation) => {
        if (
          'latitude' in geoLocation.coords &&
          'longitude' in geoLocation.coords
        )
          setDeviceLocation({
            lat: geoLocation.coords.latitude,
            lng: geoLocation.coords.longitude,
          })
      })
    }
  }, [deviceLocation])

  useEffect(() => {
    if (searchType && searchType?.value) {
      setFilterType(searchType?.value.toLowerCase().replace(/\s+/g, '-'))
      window.history.pushState({}, '', `/properties/${filterType}`)

      switch (filterType) {
        case 'condominium':
          setIsPageTitle((prev) => ({
            ...prev,
            pageTitle:
              'RFO and Pre Selling Condo in the Philippines | Avida Land',
            pageHeader: 'Our Condo Developments',
            subHeader:
              'Discover inspired living in ready-for-occupancy and pre selling condominiums in key locations across the Philippines.',
            description:
              'Discover inspired living in ready-for-occupancy and pre selling condominiums in key locations across the Philippines with Avida Land.',
          }))
          break
        case 'house-and-lot':
          setIsPageTitle((prev) => ({
            ...prev,
            pageTitle:
              'RFO and Pre Selling House and Lot in the Philippines | Avida Land',
            pageHeader: 'Our House and Lot Developments',
            subHeader:
              'Find inspired life spaces in ready-for-occupancy and pre-selling house and lot properties in the Philippines.',
            description:
              'Find inspired life spaces in ready-for-occupancy and pre-selling house and lot properties in the Philippines with Avida Land.',
          }))
          break
        default:
          setIsPageTitle(PAGE_SEO_DEFAULT_VALUE)
          break
      }
    } else {
      setIsPageTitle(PAGE_SEO_DEFAULT_VALUE)
    }
  }, [searchType, filterType])

  console.log('searchType', searchType)
  console.log('filterType', filterType)

  return (
    <Layout navButtonVariant="burger" returnPageUrl="/properties/">
      <Seo
        slug="properties"
        title={isPageSEO?.pageTitle}
        ogMetaData={{
          title: isPageSEO?.pageHeader,
          description: isPageSEO?.description || 'Avida Land Properties',
          image: propertyList && propertyList[0]?.featuredImage,
        }}
        jsonData={{
          url: `${process.env.GATSBY_SITE_URL}/properties/${filterType}/`,
          description: isPageSEO?.description || 'Avida Land Properties',
          keywords:
            'properties, condo, philippines, house and lot, avidaland, avida, apartment, property list',
        }}
      />
      <div className="our-properties">
        <div className={`search-container${checked ? ' map-search' : ''}`}>
          <h1 className="search-container-title">
            {filterType === 'house-and-lot' ? (
              <>
                Our House <span>and</span> Lot Developments
              </>
            ) : (
              isPageSEO?.pageHeader || pageContext?.pageHeader
            )}
          </h1>
          <h3 className="search-container-title-sub">
            {filterType?.length === 0 || searchType?.value === '' ? (
              <>
                Discover a wide range of real estate options - condos, house and
                lot properties, and more. <br /> Find your ideal home today.
              </>
            ) : (
              isPageSEO?.subHeader
            )}
          </h3>
          <div className="search-container-main">
            <div
              key={JSON.stringify(locationState || {})}
              className="search-container-content"
            >
              <PropertySearchInput
                placeholder="Search Location"
                setSearchTerm={setSearchTerm}
                defaultValue={searchTerm}
              />
              <PropertySearchSelect
                options={searchOptions.type}
                placeholder="Property Type"
                onChange={handleSearchTypeSelect}
                defaultValue={locationState?.searchType}
              />
              <PropertySearchSelect
                options={searchOptions.price}
                placeholder="Price Range"
                onChange={handleSearchPriceRangeSelect}
                defaultValue={searchPriceRange}
              />
              <button className="search-container-content-button" type="button">
                Find Properties
              </button>
            </div>
            <button
              className="search-button-hide"
              type="button"
              onClick={(): void => {
                setSearch(!showSearch)
                setStick(true)
              }}
            >
              Find Properties
            </button>
            <PropertySearch
              isStick={isStick}
              variant="sticky"
              color="white"
              onSearchClose={(): void => {
                setStick(false)
                setShow(false)
              }}
              onSearch={handleSearch}
              values={locationState}
              visibleAdvancedFilter
            />
            <div className="search-container-button">
              <div className="search-advanced-filter">
                <button
                  className={`filter-button-${
                    ['House and Lot', 'Condominium', ''].includes(
                      searchType?.value || '',
                    )
                      ? 'show'
                      : 'hide'
                  }`}
                  type="button"
                  onClick={(): void => {
                    setStick(true)
                    setSearch(true)
                    setShow(!show)
                  }}
                >
                  {!show ? (
                    <img src={plusIcon} alt="plusIcon" />
                  ) : (
                    <img src={minusIcon} alt="minusIcon" />
                  )}
                  <span> Advanced Search</span>
                </button>
                {['House and Lot', 'Condominium', ''].includes(
                  searchType?.value || '',
                ) &&
                  show && (
                    <div className="search-advanced-filter-content">
                      {['House and Lot', ''].includes(
                        searchType?.value || '',
                      ) && (
                        <PropertySearchSelect
                          options={searchOptions.bedroom}
                          placeholder="No. of Bedroom"
                          onChange={handleSearchBedroom}
                          defaultValue={searchBedroom}
                        />
                      )}
                      {['Condominium', ''].includes(
                        searchType?.value || '',
                      ) && (
                        <PropertySearchSelect
                          options={searchOptions.unit}
                          placeholder="Unit Type"
                          onChange={handleSearchUnitType}
                          defaultValue={searchUnitType}
                        />
                      )}
                    </div>
                  )}
              </div>
              <div className="search-advanced-toggle">
                <span> {!checked ? 'List' : 'Map'} View</span>
                <Switch
                  onChange={(): void => setChecked(!checked)}
                  checked={checked}
                  handleDiameter={25}
                  offColor="#ffffff"
                  onColor="#ffffff"
                  offHandleColor="#990D15"
                  onHandleColor="#990D15"
                  height={30}
                  width={65}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="our-properties-search-result">
          {!checked ? (
            <OurPropertiesListView
              searchResults={pagePropertyList}
              pages={pages}
              currentPage={currentPage}
              onPageClick={handlePageClick}
              searchTerm={searchTerm}
              currentSets={currentSets}
              handleAddSets={handleAddSets}
            />
          ) : (
            <OurPropertiesMapView
              searchResults={searchedPropertyList}
              pagePropertyList={pagePropertyList}
              deviceLocation={deviceLocation}
              pages={pages}
              currentSets={currentSets}
              handleAddSets={handleAddSets}
            />
          )}
        </div>
      </div>
    </Layout>
  )
}
export default OurProperties

export const pageQuery = graphql`
  query {
    allProperty(filter: { propertyName: { ne: "home_page_banner" } }) {
      nodes {
        ...OurPropertiesPagePropertyFields
      }
    }
    homePageSeo {
      seoPropertiesTitle
      seoPropertiesDescription
    }
  }
`
